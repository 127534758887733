@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Poppins', sans-serif !important;
}

.css-1nxz1sm-MuiTableRow-root {
  &:not(:last-child) {
    border-bottom: 1px solid #d7d7d7;
  }
}

.main-table-row {
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  margin-block: 4px;
}

.main-table {
  border-collapse: collapse;
  border-collapse: separate;
  border-spacing: 0 16px;
}

.main-page {
  min-height: calc(100vh - 146px) !important;
}

.main-area {
  background-color: #f7f7f7;
}

// Tabs

.MuiTabs-root {
  .MuiTab-labelIcon {
    font-size: 1.25rem;
    margin-right: 24px;
    padding: 0 12px;

    &.Mui-selected {
      color: #4DBB7F;
    }
  }

  .MuiTabs-indicator {
    height: 100%;
    background-color: rgba(77, 187, 127, 0.4);
    border-radius: 8px;
    opacity: 0.4;
  }
}

.px-0 {
  padding-inline: 0 !important;
}

.client-order-details-page {
  .services-table {
    th {
      background-color: transparent;
    }

    tr,
    thead {
      &:not(:last-child) {
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
      }
    }

    th,
    td {
      font-size: 1rem;
      color: #000000cc;

      &:not(:last-child) {
        border-right: 0.5px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.bordered-table-container {
  border-radius: 4px;
  border: 1px solid #efefef;
}

.bordered-table {
  th {
    background-color: transparent;
  }

  tr,
  thead {
    &:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }
  }

  th,
  td {
    font-size: 1rem;
    color: #000000cc;

    &:not(:last-child) {
      border-right: 1px solid #efefef !important;
    }
  }
}

.section-title {
  font-weight: 500 !important;
  font-size: 1.25rem !important;
}

.text-black {
  color: black !important;
}

.text-black-06 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.text-black-08 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.list-item {
  font-size: 1rem;
  padding-block: 0 !important;

  &.active {
    font-weight: 500;
  }
}

.border-list {
  .list-item {
    padding-block: 0.75rem !important;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.border-less-list {
  .list-item {
    &:not(:last-child) {
      padding-top: 0;
      padding-bottom: 1.25rem !important;
    }
  }
}

.order-summary-card {
  .card-title {
    // font-size: 1.25rem;
  }
}

.total-card {
  .key {
    font-size: 1.25rem;
  }
}

.flex-none {
  flex: none !important;
}

.address-card {
  .list-item {
    .MuiListItemText-root {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.fw-500 {
  font-weight: 500 !important;
}

.w-100 {
  width: 100%;
}

.chip-yellow-c {
  color: #ba8216 !important;
  background-color: #fee4a1 !important;
}

.chip-green-c {
  color: #5c9677 !important;
  background-color: #b9ebd0 !important;
}

.chip-red-c {
  color: #ff5252 !important;
  background-color: #ffcccc !important;
}

.chip-gray-c {
  color: #00000066 !important;
  background-color: #0000000d !important;
}

.pb-1rem {
  padding-bottom: 1rem !important;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  .actions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.btn-download {
  padding: 16px 30px !important;
  border: 1px solid #0000004d !important;
  background-color: white !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.upload-docs-card {
  .card-title {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.doc-upload-item {
  .upload-title {
    font-size: 1.3rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
  }
}

.upload-box-wrapper {
  max-width: 712px;
}

.upload-box {
  background: rgba(217, 217, 217, 0.1);
  border: 2px dashed rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 28px;
  text-align: center;

  img {
    width: 4rem;
    display: block;
    margin-inline: auto;
  }

  .browse {
    font-weight: 600;

    span {
      font-weight: 600;
      font-size: 1rem;
      color: #50cd89;
      text-decoration: underline;
    }
  }

  .supported-docs {
    font-weight: 400;
  }

  .supported-docs,
  .browse {
    font-size: 0.9rem;
    color: black;
  }
}

.section-title-box {
  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.user-cell {
  img {
    width: 5rem;
    height: 5rem;
    border-radius: 6px;
  }
}

.cross-icon {
  width: 2rem;
  height: 2rem;

  background: rgba(172, 172, 172, 0.2);
  border-radius: 50px;
}

.swal2-container {
  z-index: 2000;
}

.errors-textfield {
  fieldset {
    border-color: #FF5630 !important;
  }
}

.table-cell-header {
  color: white !important;
  background-color: #7a7b7c !important;
}

.client-services-table {
  th {
    background-color: transparent;
  }

  tr,
  thead {
    &:not(:last-child) {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    }
  }

  th,
  td {
    color: #000000cc;

    &:not(:last-child) {
      border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    }
  }
}

.client-services-tablecell {
  padding: 32px !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  opacity: 0.699999988079071 !important;
}

.client-services-tablebody-tablecell {
  padding: 32px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  opacity: 0.6000000238418579 !important;
}

.offers-table {
  .MuiTableContainer-root {
    box-shadow: none !important;
  }

  .MuiTable-root {
    border-collapse: separate !important;
    border-spacing: 0 10px !important;
    border: transparent !important;
  }

  .MuiTable-root th,
  .MuiTable-root td {
    border-top: 1px !important;
    border-bottom: 1px !important;
  }
}

.leaflet-container {
  height: 100vh;
  width: 100%;
}




// .leaflet-right {
//   display: none;
// }

.leaflet-routing-collapse-btn {
  top: 0px !important;
}

.leaflet-routing-alt {
  border: none !important;

  table {
    display: none;
  }

  h2 {
    display: none;
  }

  h3 {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
  }
}

.leaflet-routing-alt-minimized {
  display: none !important;
}

.map-card {
  .leaflet-container {
    height: 430px;
    max-height: 700px;
    width: 100%;
  }

  .MuiCardContent-root {
    padding-bottom: 0px !important;
  }
}

.map-card-custom-clearance {
  .leaflet-container {
    height: 290px;
    max-height: 700px;
    width: 100%;
  }

  .MuiCardContent-root {
    padding-bottom: 0px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 999s ease-in-out 0s;
}

#file-input {
  color: rgba(0, 0, 0, 0);
}


.map-card-admin-order-details {
  .leaflet-container {
    height: 270px;
    max-height: 700px;
    width: 100%;
  }

  .MuiCardContent-root {
    padding-bottom: 0px !important;
  }
}

.admin-map-card {
  .leaflet-container {
    height: 430px;
    max-height: 700px;
    width: 100%;
  }

  .MuiCardContent-root {
    padding-bottom: 0px !important;
  }
}

.completed-client-checkmark,
.cancel-client-order,
.have-issue-client-order {
  color: #8d8585;
}

.completed-client-checkmark:hover {
  color: #50cd89;
}

.cancel-client-order:hover {
  color: #eb2604;
}

.have-issue-client-order:hover {
  color: #ebd404;
}

.not-approved {
  background-color: #FAD02C;
}

.client-feedback-rating {
  svg {
    width: 94px !important;
    height: 72px !important;
  }
}

.cs-message--outgoing {

  .cs-message__content {
    background-color: #c8fad6 !important;
  }
}

.cs-message--incoming {
  .cs-message__content {
    background-color: #f4f6f8 !important;
  }
}

.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor {
  background-color: #ffff !important;
}

div.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 50px;
}

file-icon-image {
  width: 90px;
  height: 90px;
}

.caption {
  display: block;
}

.cs-message__sender-name,
.cs-message__sent-time {
  display: block !important;
}

.react-select-container {
  width: 320px;
}

@media (max-width: 599px) {
  .swal-width-confirmOrder {
    width: 100% !important;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .swal-width-confirmOrder {
    width: 80% !important;
  }
}

@media (min-width: 900px) {
  .swal-width-confirmOrder {
    width: 35% !important;
  }
}

html{
  scroll-behavior: smooth;
}